import React, { useEffect, useState } from "react";
import List from "../../../components/List/List";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { BASE_URL } from "../../../utils/config";
import Pagination from "../../../components/pagination/Pagination";
import NotFound from "../../../components/NotFound/NotFound";
import Loader from "../../../components/Loader/Loader";
const CustomerList = () => {
  const headers = ["Name", "Address", "Phone", "Email", "Ward", "Status"];

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [filterValues, setFilterValues] = useState({
    name: "",
    gender: "",
    status: "",
    bloodGroup: "",
  });
  const [gender, setGender] = useState([]);
  const [status, setStatus] = useState([]);
  const [bloodGroup, setBloodGroup] = useState([]);

  const fetchData = async (page = 1, pageSize = 10) => {
    setIsLoading(true);
    try {
      const list = await adminRequest.post(`${BASE_URL}/customer/get`, {
        firstRow: (page - 1) * pageSize + 0,
        pageSize: pageSize,
      });
      const fetchedRows = list.data.data.records.map((l) => ({
        displayData: [
          l.fullName,
          l.address,
          l.mobileNumber,
          l.email,
          l.ward.wardNumber,
          l.status.name,
        ],
        code: l.email,
      }));
      setTotalItems(list.data.data.total);
      setRows(fetchedRows);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };
  const fetchGender = async () => {
    try {
      const response = await adminRequest.get(`${BASE_URL}/genders/get`);
      setGender(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchStatus = async () => {
    try {
      const response = await adminRequest.get(`${BASE_URL}/status`);
      setStatus(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchBloodGroup = async () => {
    try {
      const response = await adminRequest.get(`${BASE_URL}/bloodGroups/get`);
      setBloodGroup(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFilteredCustomer = async (page = 1, pageSize = 10) => {
    try {
      const filterParams = {};

      if (filterValues.name) filterParams.name = filterValues.name;
      if (filterValues.bloodGroup)
        filterParams.bloodGroup = filterValues.bloodGroup;
      if (filterValues.gender) filterParams.gender = filterValues.gender;
      if (filterValues.status) filterParams.status = filterValues.status;

      const list = await adminRequest.post(`${BASE_URL}/customer/get`, {
        firstRow: (page - 1) * pageSize + 0,
        pageSize: pageSize,
        param: filterParams,
      });
      const fetchedRows = list.data.data.records.map((l) => ({
        displayData: [
          l.fullName,
          l.address,
          l.mobileNumber,
          l.email,
          l.ward.wardNumber,
          l.status.name,
        ],
        code: l.email,
      }));
      setTotalItems(list.data.data.total);
      setRows(fetchedRows);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchData(currentPage, itemsPerPage);
      fetchGender();
      fetchBloodGroup();
      fetchStatus();
    }
    return () => {
      isMounted = false;
    };
  }, [currentPage, itemsPerPage]);

  updateAuthToken();

  const getMenuItems = (row) => [
    { link: `view/${row.code}`, text: "View" },
    { link: `block/${row.code}`, text: "Block" },
  ];
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchFilteredCustomer(currentPage, itemsPerPage);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "name" && value === "") {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: "",
      }));
    } else if (
      value === "Select Status" ||
      value === "Select Gender" ||
      value === "Select Blood Group"
    ) {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: "",
      }));
    } else {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const filterFields = [
    {
      name: "name",
      label: "Name",
      type: "text",
      value: filterValues.name,
      onChange: handleFilterChange,
    },
    {
      name: "gender",
      label: "Gender",
      type: "select",
      value: filterValues.gender,
      onChange: handleFilterChange,
      options: [
        { label: "Select Gender" },
        ...gender.map((category) => ({
          label: category.gender,
          value: category.gender,
        })),
      ],
    },
    {
      name: "bloodGroup",
      label: "Blood Group",
      type: "select",
      value: filterValues.bloodGroup,
      onChange: handleFilterChange,
      options: [
        { label: "Select Blood Group" },
        ...bloodGroup.map((category) => ({
          label: category.name,
          value: category.name,
        })),
      ],
    },
    {
      name: "status",
      label: "Status",
      type: "select",
      value: filterValues.status,
      onChange: handleFilterChange,
      options: [
        { label: "Select Status" },
        ...status.map((status) => ({
          label: status.name,
          value: status.name,
        })),
      ],
    },
  ];

  const resetFilterForm = (e) => {
    e.preventDefault();
    fetchData();
    setFilterValues({
      name: "",
      bloodGroup: "",
      status: "",
      gender: "",
    });
  };
  return (
    <div className="customerListContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <List
            title="Customer List"
            headers={headers}
            rows={rows.map((row) => row.displayData)}
            link="create"
            showCreateButtonLabel={false}
            showEyeViewIcon={false}
            showFilterIcon={true}
            filterFields={filterFields}
            onFilterSubmit={handleFilterSubmit}
            getMenuItems={(row) =>
              getMenuItems(rows.find((r) => r.displayData === row))
            }
            resetFilterForm={resetFilterForm}
          />
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            onPageChange={setCurrentPage}
            onItemsPerPageChange={setItemsPerPage}
          />
        </>
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CustomerList;
