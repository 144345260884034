import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { IoPeopleOutline } from "react-icons/io5";
import { LiaUserSolid } from "react-icons/lia";
import { MdOutlineHomeWork } from "react-icons/md";
import { GiGraduateCap } from "react-icons/gi";
import { adminRequest } from "../../utils/requestMethod";
import { BASE_URL } from "../../utils/config";
import activitiesData from "./activitiesData";
import eventsData from "./eventsData";
import { trimDate } from "../../utils/dateUtil";
import { truncateContents } from "../../utils/truncateContents";
import { GoAlert } from "react-icons/go";
import DateTime from "../../components/DateTime/DateTime";
import { IoChevronUpOutline } from "react-icons/io5";
import { IoChevronDownOutline } from "react-icons/io5";
import parse from "html-react-parser";
const Dashboard = () => {
  const [userCount, setUserCount] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [showAlert, setShowAlert] = useState(true);

  const fetchNewsData = async () => {
    try {
      const response = await adminRequest.post(`${BASE_URL}/news/get`, {
        firstRow: 0,
        pageSize: 5,
      });
      setNewsData(response.data.data.records);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await adminRequest.post(`${BASE_URL}/customer/get`, {});
      setUserCount(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchUserData();
    fetchNewsData();
  });
  const toggleAlert = (e) => {
    e.preventDefault();
    setShowAlert(!showAlert);
  };
  return (
    <div className="dashboardContainer">
      <div className="dashboardContents">
        <div className="dashboardBody">
          <div className="alertContainer">
            {showAlert ? (
              <IoChevronUpOutline
                className="closeIcon"
                onClick={toggleAlert}
                title="Hide alert"
              />
            ) : (
              <IoChevronDownOutline
                className="closeIcon"
                onClick={toggleAlert}
                title="Show alert"
              />
            )}
            <span className="alertHeader">
              <GoAlert /> Emergency Alert
            </span>
            {showAlert && (
              <span className="alertContents">
                Flash flood warning in effect for low-lying areas. Please stay
                informed and follow safety guidelines.
              </span>
            )}
          </div>

          <DateTime />
          <div className="topCards">
            <div className="cardContents">
              <div className="cardContentLeft">
                <IoPeopleOutline className="cardContentLeftIcon" />
              </div>
              <div className="cardContentRight">
                <span className="cardContentRightHeader">Total Citizens</span>
                <span className="cardContentRightContent">133,073</span>
              </div>
            </div>
            <div className="cardContents">
              <div className="cardContentLeft">
                <LiaUserSolid className="cardContentLeftIcon" />
              </div>
              <div className="cardContentRight">
                <span className="cardContentRightHeader">Total Users</span>
                <span className="cardContentRightContent">{userCount}</span>
              </div>
            </div>
            <div className="cardContents">
              <div className="cardContentLeft">
                <MdOutlineHomeWork className="cardContentLeftIcon" />
              </div>
              <div className="cardContentRight">
                <span className="cardContentRightHeader">Total Household</span>
                <span className="cardContentRightContent">32,695</span>
              </div>
            </div>
            <div className="cardContents">
              <div className="cardContentLeft">
                <GiGraduateCap className="cardContentLeftIcon" />
              </div>
              <div className="cardContentRight">
                <span className="cardContentRightHeader">Literacy Rate</span>
                <span className="cardContentRightContent">83.3%</span>
              </div>
            </div>
          </div>
          <div className="activitiesAndEventContainer">
            <div className="recentActivitiesContainer">
              <div className="recentActivitiesHeader">Recent Activities</div>
              <div className="recentActivitiesBody">
                {activitiesData.map((item, index) => (
                  <div className="bodyContents" key={index}>
                    <span className="recentActivitiesBodyLeft">
                      {item.title}
                    </span>
                    <span className="recentActivitiesBodyRight">
                      {item.time}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="upcomingEventsContainer">
              <div className="upcomingEventsHeader">Upcoming Events</div>
              <div className="upcomingEventsBody">
                {eventsData.map((item, index) => (
                  <div className="bodyContents" key={index}>
                    <span className="upcomingEventsBodyLeft">{item.title}</span>
                    <span className="upcomingEventsBodyRight">{item.date}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="newsContainer">
            <div className="newsContainerHeading">Latest News</div>
            {newsData &&
              newsData.map((news, index) => (
                <div className="newsBody" key={index}>
                  <span className="newsTitle">{news.title}</span>
                  <span className="newsContent">
                    {parse(truncateContents(news.content, 30))}
                  </span>
                  <span className="newsPublishedDate">
                    {trimDate(news.recordedDate)}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
