const activitiesData = [
  {
    title: "New citizen registered",
    time: "2 hours ago",
  },
  {
    title: "Property tax collected",
    time: "4 hours ago",
  },
  {
    title: "Waste management request resolved",
    time: "Yesterday",
  },
  {
    title: "Building permit approved",
    time: "2 days ago",
  },
];

export default activitiesData;
