import React, { useEffect, useState } from "react";
import List from "../../../components/List/List";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { BASE_URL } from "../../../utils/config";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import NotFound from "../../../components/NotFound/NotFound";
import Pagination from "../../../components/pagination/Pagination";

const NoticeList = () => {
  const headers = ["Subject", "File", "Release Date", "Status"];

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [filterValues, setFilterValues] = useState({
    releasedDate: "",
    status: "",
  });
  const [status, setStatus] = useState([]);

  const fetchNotice = async (page = 1, pageSize = 10) => {
    setIsLoading(true);

    try {
      const response = await adminRequest.post(`${BASE_URL}/notice/get`, {
        firstRow: (page - 1) * pageSize + 0,
        pageSize: pageSize,
      });
      const fetchedRows = response?.data.data.records.map((notice) => ({
        displayData: [
          notice.subject,
          notice.image,
          notice.releasedDate,
          notice.status.name,
        ],
        code: notice.code,
        status: notice.status.name,
      }));
      setRows(fetchedRows);
      setTotalItems(response.data.data.total);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };
  const fetchStatus = async () => {
    try {
      const response = await adminRequest.get(`${BASE_URL}/status`);
      setStatus(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFilteredNotice = async (page = 1, pageSize = 10) => {
    try {
      const filterParams = {};

      if (filterValues.releasedDate)
        filterParams.releasedDate = filterValues.releasedDate;
      if (filterValues.status) filterParams.status = filterValues.status;

      const response = await adminRequest.post(`${BASE_URL}/notice/get`, {
        firstRow: (page - 1) * pageSize + 0,
        pageSize: pageSize,
        param: filterParams,
      });
      const fetchedRows = response?.data.data.records.map((notice) => ({
        displayData: [
          notice.subject,
          notice.image,
          notice.releasedDate,
          notice.status.name,
        ],
        code: notice.code,
        status: notice.status.name,
      }));
      setRows(fetchedRows);
      setTotalItems(response.data.data.total);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotice(currentPage, itemsPerPage);
    fetchStatus();
  }, [currentPage, itemsPerPage]);

  updateAuthToken();
  const handleBlock = async (code) => {
    Swal.fire({
      title: "Are you sure you want to block this notice?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00425A",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Block",
      html: '<textarea id="blockReason" class="swal2-textarea" placeholder="Enter reason for blocking" rows="3" style="width: 80%;"></textarea>',
      preConfirm: () => {
        const blockReason = Swal.getPopup().querySelector("#blockReason").value;
        if (!blockReason) {
          Swal.showValidationMessage("Please enter a reason for block");
        }
        return { blockReason: blockReason };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(`${BASE_URL}/notice/block`, {
            code: code,
            remarks: result.value.blockReason,
          });
          if (response.data.code == 0) {
            Swal.fire({
              title: "Blocked!",
              text: `${response.data.message}`,
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Failed!",
              text: `${response.data.message}`,
              icon: "error",
            });
          }
          fetchNotice();
        } catch (error) {
          toast.error("Something went wrong on server");
        }
      }
    });
  };
  const handleUnblock = async (code) => {
    Swal.fire({
      title: "Are you sure you want to unblock this notice?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00425A",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Unblock",
      html: '<textarea id="unblockReason" class="swal2-textarea" placeholder="Enter reason for unblocking" rows="3" style="width: 80%;"></textarea>',
      preConfirm: () => {
        const unblockReason =
          Swal.getPopup().querySelector("#unblockReason").value;
        if (!unblockReason) {
          Swal.showValidationMessage("Please enter a reason for unblock");
        }
        return { unblockReason: unblockReason };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/notice/unblock`,
            {
              code: code,
              remarks: result.value.unblockReason,
            }
          );
          if (response.data.code == 0) {
            Swal.fire({
              title: "Unblocked!",
              text: `${response.data.message}`,
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Failed!",
              text: `${response.data.message}`,
              icon: "error",
            });
          }
          fetchNotice();
        } catch (error) {
          console.log("Something went wrong on server");
        }
      }
    });
  };
  const getMenuItems = (row) => [
    { link: `view/${row.code}`, text: "View" },
    row.status !== "BLOCKED"
      ? {
          link: "#",
          text: "Block",
          onClick: (e) => {
            e.preventDefault();
            handleBlock(row.code);
          },
        }
      : {
          link: "#",
          text: "Unblock",
          onClick: (e) => {
            e.preventDefault();
            handleUnblock(row.code);
          },
        },
  ];
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchFilteredNotice(currentPage, itemsPerPage);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "releasedDate" && value === "") {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: "",
      }));
    } else if (value === "Select Status") {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: "",
      }));
    } else {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const filterFields = [
    {
      name: "releasedDate",
      label: "Released Date",
      type: "date",
      value: filterValues.releasedDate,
      onChange: handleFilterChange,
    },
    {
      name: "status",
      label: "Status",
      type: "select",
      value: filterValues.status,
      onChange: handleFilterChange,
      options: [
        { label: "Select Status" },
        ...status.map((status) => ({
          label: status.name,
          value: status.name,
        })),
      ],
    },
  ];

  const resetFilterForm = (e) => {
    e.preventDefault();
    fetchNotice();
    setFilterValues({
      releasedDate: "",
      status: "",
    });
  };
  return (
    <div className="noticeListListContainer">
      {isLoading > 0 ? (
        <Loader />
      ) : (
        <>
          <List
            title="Notice Management"
            createButtonLabel="Create Notice"
            headers={headers}
            rows={rows.map((row) => row.displayData)}
            link="create"
            showEyeViewIcon={false}
            showFilterIcon={true}
            filterFields={filterFields}
            onFilterSubmit={handleFilterSubmit}
            getMenuItems={(row) =>
              getMenuItems(rows.find((r) => r.displayData === row))
            }
            resetFilterForm={resetFilterForm}
          />
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            onPageChange={setCurrentPage}
            onItemsPerPageChange={setItemsPerPage}
          />
        </>
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default NoticeList;
