const eventsData = [
  {
    title: "Town Hall Meeting",
    date: "June 15, 2023",
  },
  {
    title: "Budget Review",
    date: "June 20, 2023",
  },
  {
    title: "Community Cleanup Drive",
    date: "June 25, 2023",
  },
  {
    title: "Local Business Forum",
    date: "July 2, 2023",
  },
];

export default eventsData;
