import React, { useEffect, useState } from "react";
import "./CreateEvent.scss";
import CustomForm from "../../../components/CustomForm/CustomForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { BASE_URL } from "../../../utils/config";
import { useNavigate } from "react-router-dom";
import convertNepaliDateToAD from "../../../utils/convertNepaliDate";

const CreateEvent = () => {
  const initialFormData = {
    name: "",
    eventDate: "",
    dateInBs: "",
    category: "",
    description: "",
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [eventCategory, setEventCategory] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const fetchEventCategory = async () => {
      try {
        const eventCategories = await adminRequest.get(
          `${BASE_URL}/eventCategory/get`
        );
        if (isMounted) {
          setEventCategory(eventCategories.data.data);
          updateAuthToken();
        }
      } catch (error) {
        if (isMounted) {
          console.log("Failed to fetch event category at the moment");
        }
      }
    };
    fetchEventCategory();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleDateChange = (value) => {
    const [YY, MM, DD] = value.split("-").map(Number);
    const formattedDate = convertNepaliDateToAD(YY, MM, DD);
    setFormData({
      ...formData,
      dateInBs: value,
      eventDate: formattedDate,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/event/create`, {
          name: formData.name,
          description: formData.description,
          eventDate: formData.eventDate,
          eventDateInBs: formData.dateInBs,
          eventCategory: {
            name: formData.category,
          },
        }),
        {
          pending: "Creating Event",
        }
      );
      if (response.data.code == 0) {
        toast.success(response.data.message, {
          autoClose: 500,
          onClose: () => navigate(-1),
        });
      }
      if (response.data.code != 0) {
        toast.error(response.data.message);
      }
      setFormData(initialFormData);
    } catch (error) {
      console.log(error);
      toast.error("Failed to create event");
    } finally {
      setIsSubmitting(false);
    }
  };
  const fields = [
    {
      name: "name",
      label: "Event Name",
      type: "text",
      value: formData.name,
      onChange: handleChange,
    },
    {
      name: "category",
      label: "Category",
      type: "select",
      value: formData.category || "",
      onChange: handleChange,
      options: [
        { label: "Select Category", value: "" },
        ...eventCategory.map((c) => ({
          label: c.name,
          value: c.name,
        })),
      ],
    },
    {
      name: "dateInBs",
      label: "Event Date",
      type: "nepali-date-picker",
      value: formData.dateInBs,
      onChange: handleDateChange,
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
      value: formData.description,
      onChange: handleChange,
    },
  ];

  return (
    <div className="createEventContainer">
      <CustomForm
        header="Create Event"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Create"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateEvent;
